export interface GenericErrorExtensions<ErrorKeys> {
  details?: {
    key: ErrorKeys;
  }[];
}

export enum LoginErrorKeys {
  error = "error",
  accountLocked = "accountLocked",
  passwordResetRequired = "passwordResetRequired",
  emailVerificationRequired = "emailVerificationRequired",
}

export interface ErrorsInterface {
  [key: string]: string;
}

export enum SignupErrorKeys {
  emailVerificationRequired = "emailVerificationRequired",
  unverifiedEmail = "unverifiedEmail",
}

export enum DeleteUserAddressErrorKeys {
  addressSubscriptionAssociation = "addressSubscriptionAssociation",
  unknownError = "unknownError",
}
