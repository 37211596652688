import React, { FC, ReactNode } from "react";
import { ApolloProvider } from "@apollo/client";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import { TenantContext } from "v3/context/tenant";
import { BannerContext } from "v3/context/banner";
import { LocalizationContext } from "v3/context/localization";
import { TenantContextInterface } from "v3/context/hooks/useTenantProvider";
import { BannerContextInterface } from "v3/context/hooks/useBannerProvider";
import { LocalizationContextInterface } from "v3/context/hooks/useLocalizationProvider";
import { PortalErrorContextInterface } from "v3/context/hooks/usePortalErrorProvider";
import client from "v3/services/graphql";
import {
  BrandConfigCustomizationsFont,
  BrandConfigTrackingFonts,
} from "v3/services/graphql/generated";
import { ThemeInterface } from "@pepdirect/v3/theme";
import { FontTrackingiFrame } from "@pepdirect/v3/ui/FontTrackingiFrame";
import { generateFontFaces } from "@pepdirect/v3/helpers/fonts";
import { useFontTracking } from "@pepdirect/v3/hooks/useFontTracking";
import { isLocalEnv } from "v3/config";
import { PortalErrorContext } from "v3/context/portalError";

interface V3AppProps {
  bannerContextValue: BannerContextInterface;
  localizationContextValue: LocalizationContextInterface;
  tenantContextValue: TenantContextInterface;
  portalErrorContextValue: PortalErrorContextInterface;
  children: ReactNode;
  fonts: BrandConfigCustomizationsFont[];
  theme: ThemeInterface;
  title: string;
  trackingFonts: BrandConfigTrackingFonts[];
}

const GlobalStyle = createGlobalStyle`
  body {
    ${({ theme }) => `
    background-color: ${theme.page?.backgroundColor || ""};
    font-size: ${theme.typography.body?.fontSize || ""};
    color: ${theme.typography.body?.color || ""};
    line-height: ${theme.typography.body?.lineHeight || ""};
    `}
  }
`;

const V3App: FC<V3AppProps> = ({
  bannerContextValue,
  localizationContextValue,
  tenantContextValue,
  portalErrorContextValue,
  children,
  fonts,
  theme,
  title = "Checkout Portal",
  trackingFonts,
}) => {
  const trackFontResources = useFontTracking(trackingFonts, title);

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: generateFontFaces(fonts),
        }}
      />
      {trackFontResources.map((resource) => (
        <FontTrackingiFrame
          resource={resource}
          key={resource}
          isLocalEnv={isLocalEnv}
        />
      ))}

      <ThemeProvider theme={theme}>
        <ApolloProvider client={client}>
          <TenantContext.Provider value={tenantContextValue}>
            <LocalizationContext.Provider value={localizationContextValue}>
              <BannerContext.Provider value={bannerContextValue}>
                <PortalErrorContext.Provider value={portalErrorContextValue}>
                  {children}
                </PortalErrorContext.Provider>
              </BannerContext.Provider>
            </LocalizationContext.Provider>
          </TenantContext.Provider>
          <GlobalStyle />
        </ApolloProvider>
      </ThemeProvider>
    </>
  );
};

export default V3App;
